import React from 'react';

import SEO from '../components/seo';
import Hero from '../components/hero';
import Navigation from '../components/navigation';
import SimpleSection from '../components/simple-section';
import NewsSection from '../components/news-section';
import Discover from '../components/discover';

import { graphql } from 'gatsby';
import ServiceAndMaintenance from '../components/service-and-maintenance';
import { SecondaryButton } from '../components/buttons';
import { Languages } from '../tools/languages';
import OurSolutions from '../components/our-solutions';
import AboutUs from '../components/about-us';

const DutchHomePage = ({ location, data, language = Languages.NL }) => {
  const { home } = data;
  const { YouTube } = home;
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={home.SEO.title}
        description={home.SEO.description}
        lang={language}
        meta={[
          {
            name: 'google-site-verification',
            content: 'TJ4qlcE85W4QzC58XExNNXV27H0nG3NDcEnxePKm2Gc',
          },
        ]}
      />
      <Navigation transparent />
      <main>
        <Hero title={home.hero.title} sliderImages={home.slider} />
        <AboutUs
          language={language}
          embedUrl={YouTube.embed}
          description={YouTube.description}
          label={YouTube.label}
        />
        <OurSolutions language={language} />
        <SimpleSection dark>
          <h2>{home.about.content}</h2>
          <br />
          <SecondaryButton to="/about/">Over ons</SecondaryButton>
        </SimpleSection>
        <ServiceAndMaintenance language={language} />
        <hr />
        <NewsSection language={language} />
        <Discover language={language} dark={true} />
      </main>
    </>
  );
};

export const query = graphql`
  {
    home: strapiHomepageNl {
      SEO {
        title
        description
      }
      slider {
        alternativeText
        formats {
          large {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      YouTube {
        embed
        description
        label
      }
      hero {
        title
      }
      about {
        content
      }
    }
  }
`;

export default DutchHomePage;
